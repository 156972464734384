<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p>职业移民第一类优先（First Preference Employment Based Immigration，简称EB-1）又称“杰出人才”移民，是美国职业类移民申请中的一类，是美国移民法专门为在艺术、科学、教育、商业和运动等领域拥有杰出才能的外籍人士提供的移民途经。杰出人才移民是美国政府“招才纳贤”，积极吸引招纳世界各国优秀人才这一精神在移民政策中的具体体现。</p>
        <p>&nbsp;</p>
        <p><img loading="lazy" class="aligncenter size-full wp-image-22917" src="@/assets/news/5/1.jpg" alt="" width="516" height="328"></p>
        <p><span style="color: #0000ff;"><strong>项目优势</strong></span></p>
        <p><strong>1、涉猎领域宽泛，可接受的职业众多</strong></p>
        <p>美国EB-1A杰出人才移民的候选人范围涉猎十分宽泛，包括体育、教育、艺术、科学、商业等领域的各类人才均有望申请。</p>
        <p><strong>2、审批快，无排期</strong></p>
        <p>移民局为EB-1A提供加急申请通道。如果申请加急，能够在申请提交的15天之内获得移民局的处理结果。并且目前EB-1A没有签证排期，获批后可以直接启动移民签证申请流程。</p>
        <p><strong>3、没有资产额和投资要求</strong></p>
        <p>申请没有最低资产额要求，也无需投入资金。</p>
        <p><strong>4、无需雇主，无需劳工证</strong></p>
        <p>整个申请过程都无需美国雇主出具任何担保或证明文件，也无需申请劳工证，非常方便灵活。</p>
        <p><img loading="lazy" class="aligncenter wp-image-22915" src="@/assets/news/5/2.jpg" alt="" width="677" height="451"  sizes="(max-width: 677px) 100vw, 677px"></p>
        <p><strong>5、一人申请，全家移民</strong></p>
        <p>申请者获得批准，其配偶及21周岁以下子女均可同时申请绿卡，子女可享受免费公立教育，全家都可自由就业。</p>
        <p><strong>6、配额多，无需排期</strong></p>
        <p>“杰出人才”移民属第一类优先移民，全年配额约4万个，排期时间短。</p>
        <p><strong>7、灵活的申请条件</strong></p>
        <p>对申请人无语言、无年龄和学历等条件限制，居住要求宽松。</p>
        <p><img loading="lazy" class="aligncenter size-full wp-image-22916" src="@/assets/news/5/3.jpg" alt="" width="640" height="480"></p>
        <p><strong>适合人群</strong></p>
        <p>“杰出人才”或EB-1A主要指的是那些在科学、艺术、教育、商业、或体育五大领域中具有特殊才能，取得很高成就，并且享有或国际级的声誉与成就，其成就和贡献在该领域得到广泛认可的杰出专业人才。这些人应是被认为是该领域中出类拔萃的人物之一 。同时申请人在获得杰出人才绿卡后，将在美国继续从事其领域内的工作，并且其工作会对美国社会的相关发展带来贡献。</p>
        <p>简单来说，就是证明在所从事的领域内，获得了比绝大多数人优秀的成就。</p>
        <p>主要针对以下行业的申请人：</p>
        <p><strong>科技人才：</strong></p>
        <p>科学家、院士、发明家、各级科研机构和公司的高级工程师、高级程序师、计算机、医师、医学家、各学科正副教授、博士后、博士等。</p>
        <p><strong>艺术家：</strong></p>
        <p>画家、书法家、舞蹈家、音乐家、音乐指挥家、歌唱家、歌星、影星、名模、电视主持、影视导演、编辑师、动画设计师等；各级特级教师、教育理论家等。</p>
        <p><strong>商业人才：</strong></p>
        <p>杰出企业家、贸易商人、管理、金融、广告策划、市场营销、管理咨询、经济师等。</p>
        <p><strong>运动人才</strong>：</p>
        <p>运动员、教练员等。</p>
        <p><strong>教育人才：</strong></p>
        <p>教育家、教育学学者等。</p>
        <p><strong>申请条件</strong></p>
        <p>很多申请人会以为“杰出人才”的标准高不可攀。实际上并非如此。</p>
        <p>移民局有两种衡量标准，第一种标准确实比较难达到，但第二种标准相对宽松，很多申请人都是基于符合第二种标准而成功获批的。</p>
        <p>对于“杰出人才”，移民局会从如下两个方面的标准进行衡量：<br>
          1. 申请人是否曾经获得过一项或一项以上的重大国际奖励；</p>
        <p>如果是符合这个标准的“杰出人才”，申请人只需证明他/她曾经获得过一项以上的重大国际奖励，仅凭此项国际奖励，无须提供其它证据，即可满足杰出人才的标准，轻而易举地移民美国。</p>
        <p>至于什么样的国际奖项才是被移民法接受的重大国际奖励，移民法并无确切定义或具体标准。一般来说，诺贝尔奖、奥斯卡奖、普利策奖、奥林匹克奖牌或奖杯，或国际重大发明创造，都可被视作重大国际奖励。</p>
        <p>但是，并不是只有获得诺贝尔奖或奥林匹克奖之类的重大国际大奖的人才算是杰出人才。移民法还提供了另外一种相对宽松的衡量标准，申请人只需符合移民法规定的十条标准中的任意三条，就可申请杰出人才移民。我们为客户申报的许多杰出人才成功案例，都是以下段里的标准提出申请并获得成功。</p>
        <p>2. 也就是说，申请人如果符合移民法规定的以下十条申请条件中的任意三个，即可提交申请：</p>
        <p>* “全国性或国际性奖项”。在其领域內，曾获得稍次一些的国家奖（不一定是美国的）或国际认可奖项，证明在该领域內具有优异成就；</p>
        <p>* “受邀请专业协会会员资格”。此类专业协会由该领域的国家或国际评定具有杰出成就人士才能加入为会员；</p>
        <p>* “媒体的报道”。专业刊物及主要商业出版物，或主要媒体上发表过关于此申请人及其相关工作的报导，或曾有重要媒体报导过申请者以及申请者在所属专业领域內的成就；</p>
        <p>* “评审鉴定”。在其领域內，曾经以个人或委员会成员身份担任评审，对其专业领域或其它相关领域內的其它人的作品进行评审鉴定；</p>
        <p>* “原创性的重要贡献”。在其领域內，曾经作出过学术研究或艺术上的独创、原创性的重要贡献；</p>
        <p>* “学术文章”。在所属专业领域內的国内、国际刊物期刊或其它重要媒体上发表过的专业作品，如学术论文，书籍、学术文章等；</p>
        <p>* “展览或展示”。在或具有较高声誉或较大影响的艺术展览会或其他场所上，举办作品展览或其他展示活动；</p>
        <p>* “作用/关键作用”。在工作机构、重要专业组织、团体担任主要或其它重要职务；</p>
        <p>* “高薪”。在相关领域，申请人的薪资、报酬、或待遇远远高于其它同行；</p>
        <p>* “商业成功”。在表演、艺术上获得商业方面的成功，并通过票房收入、影片、录音带、录影带、DVD等的销售量得到反映。</p>
        <p><strong>划重点</strong></p>
        <p>正所谓“三百六十行，行行出状元”，从我们过往协助申请人递交过的成功案例来看，美国杰出人才并非高不可攀，如何根据经验，基于申请人的背景，有重点地贴合十条标准中的三条，对申请文件中的证据进行组织、提炼、升华，突出呈现申请人的优势，是成功的关键。建议申请人在听到“杰出”二字时，先别轻易否定自己，不妨联系我们为您做全方位的评估，或许下一个成功移民美国的，就是您了。</p>
        <p><strong>相关问答</strong></p>
        <p><strong>什么是EB-1A优先日期？</strong></p>
        <p><strong>答：</strong>优先日期是移民局收到EB-1A申请的日期。</p>
        <p><strong>美国杰出人才绿卡EB-1A申请至少要准备哪些文件？</strong></p>
        <p><strong>答：</strong>EB-1A的申请需要提交I-140表格，以及证明申请人符合EB-1A条件的材料文件。</p>
        <p>具体的证明材料内容视具体申请类别及领域的不同标准而有所区别。</p>
        <p><strong>艺术家或者音乐家可以申请EB-1A吗？</strong></p>
        <p><strong>答：</strong>可以。音乐家、画家、歌唱家、舞蹈家和其他表演艺术家都属于艺术类，完全符合美国杰出人才绿卡EB-1A要求的行业。</p>
        <p><strong>发表了多少篇文章才能满足EB-1A的要求？</strong></p>
        <p><strong>答：</strong>美国移民局并没有发布关于发表文章数量的规定。事实上，发表文章的数量只是一个可选择的标准，即，拥有一定数量的发表文章仅表示申请者满足EB-1A申请标准（10条）中的其中一条。</p>
        <p>但对于研究人员和科学家来说，如果没有文章发表数量的话，想要成功申请EB-1A会更加困难。</p>
        <p><strong>中国的专利（发明专利和实用性专利都有）有用吗？</strong></p>
        <p><strong>答：</strong>非常有用，但是光是只有这个不够。</p>
        <p><strong>要申请美国杰出人才绿卡EB-1A，一定要在美国境内吗？</strong></p>
        <p><strong>答：</strong>不一定。只要符合EB-1A的要求，无论是否在美国境内，都可以进行申请。</p>
        <p><strong>如果没有一个永久性工作承诺，可以申请EB-1A吗？</strong></p>
        <p><strong>答：</strong>可以。EB-1A申请无需提供雇主支持及永久性工作承诺。</p>
        <p><strong>“补充材料通知”是什么？</strong></p>
        <p><strong>答：</strong>有时候，移民局会认为EB-1A申请中并未充分证明申请人符合EB-1A的申请标准。</p>
        <p>在这种情况下，比起直接否决该申请，移民局更愿意给申请人提供更多证明材料的机会。因此他们会发出“补充材料通知”。</p>
        <p><strong>在EB-1A申请标准下，推荐信是什么？</strong></p>
        <p><strong>答：</strong>推荐信是由申请人/受益人所属领域内的，或其他相关领域的权威人士出具的推介信件。推荐信会详细地介绍EB-1A申请人/受益人的能力和成就。</p>
        <p>作为EB-1A申请所需的证明文件，推荐信扮演着非常重要的角色。</p>
        <p><strong>应该从什么人那里获取推荐信呢？</strong></p>
        <p><strong>答：</strong>推荐信应该由申请人/受益人所属领域内的或学者出具。通常，我们的客人会邀请他们以前的教授、雇主、同事或他们在同行会议上认识的其他人等来出具推荐信。总之，任何对申请人/受益人的工作和工作领域有一定了解、或对其工作有直接/间接发言权、或在其工作领域有一定专业技能的人，都可以出具推荐信。</p>
        <p><strong>申请EB-1A时，选择加急是否会影响成功率？</strong></p>
        <p><strong>答：</strong>在申请EB-1A时，申请人可以选择加急通道来加快审批速度。实际上，并没有任何官方数据显示加急申请会降低EB-1A的成功率。</p>
        <p>EB-1A的申请是否通过，还是要依据申请材料是否充分。</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "5",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:5,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>